(function($) {
  $(function() {

    function ChangeLogoDropDownMobile (){
      if($(window).width()<=991){
        $('.menu__small-logo').addClass('menu__small-logo--mobile');
        if($('menu__small-logo').hasClass('menu__small-logo--mobile')){
          setTimeout(function(){
            $('.menu__small-logo').removeClass('menu__small-logo--mobile');
          }, 500)
        }
      }
    }


    if($(window).width() <= 991){
      $('.approach-to-work__inner ul').slick({
        prevArrow: $('.approach-to-work__prev'),
        nextArrow: $('.approach-to-work__next'),
      });
    }


    $('.drop-down').on('click', function(){
      $('.drop-down__top-branch').toggleClass('drop-down__top-branch--active')
      $('.drop-down__middle-branch').toggleClass('drop-down__middle-branch--active')
      $('.drop-down__bottom-branch').toggleClass('drop-down__bottom-branch--active')
      $('.menu').toggleClass('menu--active')
        ChangeLogoDropDownMobile ()
        if($('body').hasClass('body_hiden')){
          setTimeout(function(){
            $('body').toggleClass('body--hidden')
          },10)
        }
        else{
          setTimeout(function(){
            $('body').toggleClass('body--hidden')
          },500)
        }
    })

    // ---> CHANGE LABEL TEXT WHEN YOU CHOSE SOME FILE
    $('input[type="file"]').on('change', function(e){
      $('label[for="CV"]').text(e.target.files[0].name)
    })
    // <--- CHANGE LABEL TEXT WHEN YOU CHOSE SOME FILE



    $('.vacancie h3 a').on('click', function(e){
      e.preventDefault()
      $('.information').addClass('information_active');
      $('big-text').show()
      setTimeout(function(){
        $('.vacancie').toggle()
        $('body').toggleClass('body--hidden')
      }, 1000)
    })

    $('.information__text a').on('click', function(){
      setTimeout(function(){
        $('body').toggleClass('body--hidden')
        $('.vacancie').toggle()
        $('.information').removeClass('information_active')
      }, 10)

    })
  })

  $('.choose-bar a').on('click', function(e){
    e.preventDefault();
    $('.choose-bar a').removeClass("choose-bar--color-red")
    $(this).addClass("choose-bar--color-red")
    var tags = $(this).attr("data-tags")
      $('.clients__item').addClass('clients__item--opacity-half')
      if(tags == "All"){
        $('.clients__item').removeClass('clients__item--opacity-half')
      }else{
        $('.clients__item').addClass('clients__item--opacity-half')
        $('.clients__item[data-tags="' + tags + '"]').removeClass('clients__item--opacity-half')
      }
  })

  function changeName () {
    console.log($('.first-slider .img-active'), $('.first-slider .img-active').attr('data-name'), $('.first-slider .img-active').attr('data-work'))
    $('.signature .name').text($('.first-slider .person.img-active').attr('data-name'));
    $('.signature .work').text($('.first-slider .person.img-active').attr('data-work'));
}

  $('body').removeClass("main-body")


  if(window.location.pathname == "/success"){
    $('body').removeClass('main-page-body--color-white')
  }


  var totalSlides = 0;
  $('.first-slider').on('init', function(event, slick) {
    totalSlides = slick.$slides.length - 1;
  })

  
  $('.first-slider').on('init', function(event, slick){
    changeName()
  });

  $('.first-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    variableWidth: true,
    infinite: true,
    centerMode: true,
    // autoplay:true,
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    responsive: [{
        breakpoint: 992,
        settings: {
          mobileFirst:true,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '205px',
          variableWidth: true,
          infinite: true,
          draggable: false
        }
      }]
  })



  


  if($(window).width() > 991){
    $('.information form input[name="what_you_want_to_do"]').attr('placeholder', 'Що ти хочеш робити?')
    var largeImg = $(".slick-active").length + 1

    $(".slick-slide").siblings().children().css({
      'width': '50%',
    })

    $(".slick-current").siblings().eq( largeImg ).addClass('img-active')

    $(".slick-current").siblings().eq( largeImg ).children().css({
      'width': '137%',
      "margin": 'unset',
    })

    $(".slick-current").siblings().eq( largeImg ).css('z-index', '10')
    
  
    $('.first-slider').on('beforeChange ', function(event, slick, currentSlide, nextSlide ){
      setTimeout(function(){
        $(".slick-slide").removeAttr('style');
      }, 300)
      $(".slick-slide img").css({
        'width': '50%',
        "margin-left": 'unset',
        'display': 'block'
      });
      
      
      var add = 0;
      if (currentSlide === 0 && nextSlide === slick.$slides.length - 1) {
        add = 1;
      } else if (nextSlide > currentSlide || (currentSlide === (slick.$slides.length - 1) && nextSlide === 0 )) {
        add = 1;
      } else {
        add = 1;
      }
      $('.name').text($(".slick-slide[data-slick-index=" + (nextSlide + add) + "]").attr('data-name'))
      $('.work').text($(".slick-slide[data-slick-index=" + (nextSlide + add) + "]").attr('data-work'))
      $(".slick-slide").removeClass('img-active')
     
      setTimeout(function() {
        $(".slick-slide[data-slick-index=" + (nextSlide + add) + "]").css({
          'z-index': 10,
          'transform': 'translateY(0%)'
        });
        
        $(".slick-slide[data-slick-index=" + (nextSlide + add) + "] img").css({
          width: '137%',
          "margin-left": '0',
          
        });
        $(".slick-slide[data-slick-index=" + (nextSlide + add) + "]").addClass('img-active')
        
      }, 500);
      
    });

  }else{
    $('.slick-slide img').css('width', '50%')
    $('.slick-current img').css('width', '90%')
    $('.name').text($(".slick-current").attr('data-name'))
    $('.work').text($(".slick-current").attr('data-work'))
    
    $('.first-slider').on('beforeChange ', function(event, slick, currentSlide, nextSlide ){
        slideId = currentSlide + 1
        $('.slick-slide img').css({
          'width': '50%',
          'transition': 'all 0.5s linear'
        })
        var add = 0;
        if (currentSlide === 0 && nextSlide === slick.$slides.length - 1) {
          add = -1;
        } else if (nextSlide > currentSlide || (currentSlide === (slick.$slides.length - 1) && nextSlide === 0 )) {
          add = 1;
        } else {
          add = -1;
        }
        setTimeout(function(){
          $(".slick-slide[data-slick-index=" +nextSlide+ "] img").css('width', '90%')
        },500)
        $('.name').text($(".slick-slide[data-slick-index=" + (currentSlide + add) + "]").attr('data-name'))
        $('.work').text($(".slick-slide[data-slick-index=" + (currentSlide + add) + "]").attr('data-work'))
    });

  }
  if (window.location.pathname == '/contacts.html'){
    var map = L.map('mapid').setView([49.8625165,24.0446278], 16);

    L.tileLayer('https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png', {
         maxZoom: 20,
         minZoom: 0
     }).addTo(map);
     
     L.marker([49.8625165,24.0446278]).addTo(map);
  }

  $('.case_slider1').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    arrows: true
    });
    
  $('.case_slider2').slick({
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 1,
    arrows: true

  });



  
})(jQuery);
if (window.innerWidth >= 991 & window.location.pathname == '/'){
  var scene = document.getElementById('scene');
  var parallaxInstance = new Parallax(scene);
  var scene2 = document.getElementById('scene2');
  var parallaxInstance = new Parallax(scene2);
  var scene3 = document.getElementById('scene3');
  var parallaxInstance = new Parallax(scene3);
  var scene4 = document.getElementById('scene4');
  var parallaxInstance = new Parallax(scene4);
  // var scene5 = document.getElementById('scene5');
  // var parallaxInstance = new Parallax(scene5);
}